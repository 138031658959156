#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

#background {
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  background-image: url("../public/background.jpeg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  filter: blur(5px);
  user-select: none;
  pointer-events: none;
  z-index: -1;
}

#cover {
  position: absolute;
  top: 5%;
  right: 5%;
  bottom: 5%;
  left: 5%;
  background-image: url("../public/cover_image.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  user-select: none;
  pointer-events: none;
}

@keyframes float-anim {
  0%,
  100% {
    transform: translatey(-10px) scale(1.05);
  }
  50% {
    transform: translatey(10px) scale(0.995);
  }
}
.float {
  animation: float-anim 6s ease-in-out infinite;
}
